.containerPortfolioFull {
  background-color: #333333;
  height: auto;
}

.containerPortolio {
  display: flex;
  flex-direction: column;

}

.boxImgPortolio {
  height: 32rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../../../public/img/capaPortfolio.png);
}

.boxInfosPortfolioInicial {
  display: flex;

  flex-direction: column;
  padding: 8rem 11.9rem 12rem;

  background-color: #FFFFFF;
}

.boxTextIni {
  display: flex;

  gap: 3.1rem;
  flex-direction: column;
}

.boxTxt {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 3.4rem;

  color: #333333;
}

.boxTitle{
  display: flex;
  flex-direction: column;
  align-items: start;
}

.boxTitle div {
  display: flex;
  align-items: center;

  height: 2rem;
  width: auto;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 5rem;
  line-height: 6rem;
  color: #333333;
  background-color: #62CBE9;
}

.containerTime {
  display: flex;

  gap: 6.4rem;
  align-items: center;

  padding: 5.6rem 5rem 10rem;
  flex-direction: column;

  background-color: #FFFFFF;
 
}

.ContainerTitleTime {
 width: 100%;
}

.boxTitleTime {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.boxTitleTime div{
  display: flex;

  align-items: center;

  background-color: #62CBE9;

  height: 2.2rem;
  width: auto;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 5rem;
  line-height: 6rem;

  color: #333333;
}

.boxPicturesTime {
  display: flex;
  justify-content: space-around;
  width:100%;
}

.boxPicture {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  align-items: center;
}

.pictureTime{
   width: 26.4rem;
  height: 26.4rem;

  background-size: cover;
  background-repeat: no-repeat;

   border-radius: 50%;
  border: 0.4rem solid #62CBE9;
}

.pictureTimeMi {
  background-image: url(../../../public/img/equipe03.svg);
}

.pictureTimePrimo {
  background-image: url(../../../public/img/equipe01.svg);
}

.pictureTimeMoreno {
  background-image: url(../../../public/img/equipe2.svg);
}

.nameTime {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 2.6rem;

  text-align: center;
  color: #333333;
}

.textTime {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;

  width: 36rem;
  

  text-align: center;

  color: #333333;
}

#boxModal {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2.4rem;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  box-shadow: 0rem 0.3rem 1.7rem rgba(33, 33, 33, 0.25);
  border-radius: 0.8rem;

}

.boxTitleModal {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.titleModal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;

  color: #333333;
}

.btnModal {
  width: 1.6rem;
  height: 1.6rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../public/img/closeModal.svg");
  cursor: pointer;
}

.boxVideosModal {
  display: flex;

  justify-content: space-between;

  gap: 4.8rem;
  padding: 5.2rem 6rem 4rem;
}

.boxInternoVideoModal {
  display: flex;

  flex-direction: column;

  gap: 1.6rem;
  
}

.videoModal{
  display: flex;

  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 31rem;
}

#videoModal1 {
  background-image: url("../../../public/img/rec052.svg");
}

#videoModal2 {
  background-image: url("../../../public/img/image5.svg");
}

#videoModal3 {
  background-image: url("../../../public/img/image6.svg");
}

#videoModal4 {
  background-image: url("../../../public/img/image7.svg");
}

.textVideoModal {
  display: flex;

  flex-direction: column;

  justify-content: end;
  align-items: center;

  width: 20rem;
  height: 5rem;


  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;

  color: #333333;
}

.btnPlayModal {
  transition: 0.3s;
  display: flex;

  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 50%;
  border: 0.1rem solid #333333;
  width: 5.6rem;
  height: 5.6rem;
  cursor: pointer;
}

.btnPlayModal:hover{
  transition: 0.3s;
  background-color: #62CBE9;
}

.iconPlayModal {
  width: 1.6rem;
  height: 1.6rem;
  background-image: url("../../../public/img/iconPlay.svg");
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 767px) {

  .boxImgPortolio {
    height: 16.7rem; 
  }

  .boxInfosPortfolioInicial {
    padding: 2.4rem 1.6rem 3rem;
  }

  .boxTextIni {
    gap: 1.6rem;
  }

  .boxTitle{
    font-size: 2.4rem;
    line-height: 3.4rem;
  }
  
  .boxTxt {
    
    font-size: 1.6rem;
    line-height: 2.6rem;
  }


  .containerTime {
    display: flex;
  
    gap: 6.4rem;
    align-items: center;
  
    padding: 5.6rem 5rem 10rem;
    flex-direction: column;
  
    background-color: #FFFFFF;
   
  }

  
.ContainerTitleTime {
  width: 100%;
}

.boxTitle div {
  font-size: 2.4rem;
  line-height: 3.4rem;
  
}

.boxTitleTime div{
  font-size: 2.4rem;
  line-height: 3.4rem;
}

.boxPicturesTime {

  flex-direction: column;
  gap: 4rem;
  height: auto;
  width: 100%;
}

.pictureTime{
  width: 16.3rem;
  height: 16.3rem;
}

.nameTime {
  font-size: 2.4rem;
  line-height: 3.4rem;
}

.textTime {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

#boxModal {
  height: 100%;
  width: 100%;
  padding: 1.6rem;

  gap: 2.5rem;
}

.titleModal {
  font-size: 2rem;
}

.boxVideosModal {
  display: flex;

  justify-content: center;
  flex-wrap: wrap;

  gap: 1.5rem;
  padding: 0;
}

.boxInternoVideoModal {
  gap: 0.8rem;
}

.videoModal{

  width: 15rem;
  height: 25rem;
}

.textVideoModal {
  width: 13rem;
  height: 4rem;

  font-weight: 600;
  font-size: 1.4rem;

}

.btnPlayModal {
  width: 4rem;
  height: 4rem;
  
}

.iconPlayModal {
  width: 1rem;
  height: 1rem;
  
}

}