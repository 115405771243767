.container {
  background-color: gray;
  height: auto;
}

.contactPage {
  display: flex;

  justify-content: center;
  align-items: center;

  

  height: 100vh;
  background-image: url(../../../public/img/imgPortalAventuras.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.contactPageInfo {
  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;
  gap: 6rem;

}

.contactPageInfo h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 8rem;
  line-height: 9.8rem;
  color: #ffffff;
}

.contactList {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  
  gap: 2.4rem;

}

.contactList h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.8rem;
  text-align: center;
}

.contactList a {
  all: unset;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  cursor: pointer;
}

.contactSocialMedia {
  display: flex;
  flex-direction: column;
}

.contactSocialMedia p {
  font-size: 2.4rem;
  margin-bottom: 1.6rem;
  font-weight: 700;
}

.contactSocialMediaIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
}

@media screen and (max-width: 767px) {

  .contactPage {
    height: 30rem;
    width: 100%;
  }
  
  .contactPageInfo {
    gap: 1.6rem;
  }

  .contactPageInfo h1 {
    font-weight: 800;
    font-size: 2.4rem;
    line-height: 9.8rem;
    color: #ffffff;
  }

  .contactList {
    gap: 1.6rem;
  }
  
  .contactList h2 {
    font-size: 1.8rem;
    line-height: 2.3rem;
   
  }
  
  .contactList a {
    font-size: 1.6rem;
  }

  .contactSocialMedia {
    display: flex;
    flex-direction: column;
  }
  
  .contactSocialMedia p {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }
  
  .contactSocialMediaIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.6rem;

    margin-bottom: 3rem;
  }
}