.container {
  
  background-color: #fff;
  

}

.screamVideo {
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  background-color: #fff;


  padding-bottom: 3.2rem;
}


#mainVideo{

  width: 100%;
  height: 100%;
  
  object-fit: cover;
}

#boxTextVideoPrincipal {
  display: flex;
  flex-direction: column;
align-items: center;

  gap: 4rem;
  position: absolute;
  width: 100%;

}

#boxTextVideoPrincipal h1 {
  text-align: center;
  color: #ffffff;


  font-size: 5rem;
  font-weight: 700;
  line-height: 5.9rem;
  letter-spacing: 0rem;
}

#boxTextVideoPrincipal p {
  text-align: center;
  color: #ffffff;

  font-weight: 600;
  font-size: 2.6rem;
  line-height: 3.4rem;

  width: 86rem;
}

#boxTextVideoPrincipal div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
}

#boxTextVideoPrincipal div a img{
  width: 5rem;
  height: 5rem;
 }

.containerProducts {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 11.5rem;
  gap: 4rem;

  background-color: #ffffff;
}

.headerProducts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.headerProducts div {
  display: flex;
  align-items: center;

  width: auto;
  height: 2rem;


  Font-family: Roboto;
  font-weight: 700;
  Font-size: 3.6rem;
  color: #333333;
  background-color: #becc40;
}

.containerBolProducts {
  display: flex;

  justify-content: center;
  align-items: center;
  width: 100%;

}

.boxProducts {
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 1.6rem;

  width: 23rem;
  height: 18rem;
}

.bolProdutcs {

  display: flex;
  width: 11.2rem;
  height: 11.2em;

  border-radius: 50%;
  
}

.txtProduct {
  width: 100%;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;

  text-align: center;

  color: #333333;

}

.screamPortalAventuras {
  display: flex;

  justify-content: center;
  align-items: center;

  height: 100vh;
  background-image: url(../../../public/img/imgPortalAventuras.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.infosPortal {
  display: flex;

  flex-direction: column;

  gap: 4rem;
  align-items: center;

  height: 32rem;
}

.infosPortal h1 {
  font-weight: 800;
  font-size: 3.6rem;
  line-height: 4.8rem;

  color: #ffffff;
}

.infosPortal p {
  font-weight: 400;
  font-size: 2.6rem;
  line-height: 3.6rem;

  text-align: center;
  color: #ffffff;
}

#btnPortalAventuras {
  all: unset;

  display: flex;

  justify-content: center;
  align-items: center;

  background-color: transparent;

  width: 14.2rem;
  height: 4rem;
  border: 0.1rem solid #ffffff;
  border-radius: 3.2rem;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;

  color: #ffffff;

  cursor: pointer;
}

#btnContrate {
  all: unset;

  display: flex;

  justify-content: center;
  align-items: center;

  background-color: transparent;

  width: 14.2rem;
  height: 4rem;
  border: 0.1rem solid #333333;
  border-radius: 3.2rem;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;

  color: #333333;

  cursor: pointer;
}

#btnPortalAventuras:hover,
#btnContrate:hover {
  background-color: #becc40;
}

@media screen and (max-width: 767px) {

  .container{
    margin-top: 6rem;
  }

  #boxTextVideoPrincipal{
    gap: 1.6rem;
  }

  #boxTextVideoPrincipal h1{
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  #boxTextVideoPrincipal p{
    font-size: 1.4rem;
    line-height: 2.2rem;

    width: 34rem;

    font-weight: 600;
  }

  #boxTextVideoPrincipal div {
    gap: 1rem;
  }
  
  #boxTextVideoPrincipal div a img{
    width: 4rem;
    height: 4rem;
   }

  .containerProducts{
padding: 2.4rem 1.6rem;
  }

  .headerProducts{
gap: 1.8rem;
  }

  .headerProducts div{
    font-size: 2rem;
    line-height: 3.4rem;
  }
  
  .containerBolProducts {
    display: flex;
  
    flex-wrap: wrap;
    
    
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  
  }

  .boxProducts {
    gap: 1.2rem;
  
    width: 17rem;
    height: 18rem;

    margin-bottom: 1.6rem;
  }

  .txtProduct {
    font-size: 1.6rem;
  }

  .screamPortalAventuras{
    height: auto;
    padding: 7rem 2rem 0;
  }

  .infosPortal{
    gap: 1.6rem;
  }
  
  .infosPortal h1 {
    font-size: 2.4rem;
    
  }
  
  .infosPortal p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.6rem;
  
    text-align: center;
    color: #ffffff;
  }
}
