.header {

 width: 100%;
  height: 8.4rem;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1.6rem 11.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1001;
}

.header button {
  all: unset;
  background-color: transparent;

  width: 14.2rem;
  height: 4rem;
  border: 0.1rem solid #333333;
  border-radius: 3.2rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 100%;

  text-align: center;

  color: #333333;

  cursor: pointer;
}

.logo-icon {
  border-radius: 50%;
  cursor: pointer;
  width: 6rem;
  height: 6rem;
}

.navButtonHeader{
  width: 15rem;
}


.header nav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.6rem;
  width: 5.6rem;
  background-color: transparent;
  cursor: pointer;
  padding: 1.6rem;
  border: solid 0.1rem #000;
  border-radius: 50%;
}

.menu-icon {
  height: 1.6rem;
  width: 1.6rem;
}

.buttonsHome, .buttonsPortfolio {
  transition: 0.3s;
}

.buttonsHome:hover {
  transition: 0.3s;
  background-color: #becc40;
}

.buttonsPortfolio:hover {
  transition: 0.3s;
  background-color: #62CBE9;
}

.modal{
  height: 100vh;

  background-color: #333333;
  position: fixed;
  z-index: 1001;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1.4rem  15.4rem 1.4rem 11.9rem ;
  
}

.btnCloseModalHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.6rem;
  width: 5.6rem;

  background-color: transparent;
  cursor: pointer;
  padding: 1.6rem;
  border: solid 0.1rem #ffff;
  border-radius: 50%;
  color: #ffff;
}

.btnSvg {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 5.6rem;
  width: 5.6rem;
  
  border: solid 0.1rem #ffff;
  border-radius: 50%;
  cursor: pointer;
}

.btnSvg img{
  width: 1.6rem;
  height: 1.6rem;
}

.boxBtnHeader {
  display: flex;

  padding-left: 13.7rem;
  align-items: center;
  width: 46.4rem;
  height: 8.4rem;
}

.boxNavModal {
  display: flex;
  flex-direction: column;

  gap: 2.4rem; 
}

.titleModalHeader {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 3.2rem;

  cursor: pointer;

  color: #ffffff;
}

.logoHeader {
  width: 12.1rem;
  height: 12.1rem;
  background-image: url("../../../public/img/sitefuja1.svg");
  cursor: pointer;
}

.containerFooterModal {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}

.footerModalImg{
  width: 12rem;
  height: 12rem;
}

.textModalFooter {
  width: 21rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;

  line-height: 3.6rem;

  color: #ffffff;
}

@media screen and (max-width: 767px) {
  
  .header {
    height: 6rem; 
    padding: 1.2rem ;

   justify-content: center;
   position: fixed;
   top: 0;

  }
  
  .header button{
    display: none;
  }

  .logo-icon {
  
    width: 4rem;
    height: 4rem;
  }

  .navButtonHeader{
    width: auto;
  }

  .header nav
  {
    top: 0.8rem;
    left: 1.6rem;
    position: absolute;
    width: 4.6rem;
    height: 4.6rem;
  }

  .modal{
    width: 85vw;
    padding: 1.2rem 1.6rem;
    justify-content: flex-start;
    
    position: fixed;
   top: 0;
   z-index: 1002;
  }

  .btnSvg{
    width: 4.6rem;
    height: 4.6rem;
  }

  .boxNavModal{
    margin-top: 5rem;
    margin-bottom: 16rem;

  }

  .footerModalImg{
    width: 8rem;
    height: 8rem;
  }
}