.containerDepoimentos {
  padding: 5.6rem 12.1rem 14rem;
  
  display: flex;
  flex-direction: column;
    gap: 6.4rem;
  }
  
  .ContainerTitleDepoimento {
   width: 100%;

   font-family: "Roboto";
    font-weight: 800;
    Font-size: 5rem;
    line-height: 6rem;

    color: #fff;
  }
  
  .containerInfoDepoimentos {
   display: flex;
   width: 100%;
   gap: 2.4rem;
  }
  
  .boxDepoimentos {
    
  width: 55.2rem;
  height: 30.5rem;
  background-color: #F4F4F4;
  border-radius: 1rem;

  padding: 3rem 3.6rem 8rem;

    display: flex;
    flex-direction: column;

    justify-content: space-between;
    align-items: center;

    position: relative;
   
  }

  .textDepoimentos {
    Font-family: "Roboto";
    font-weight: 400;
    Font-size: 2.4rem;
    Line-height: 3.4rem; 

    color: #333333;

    width: 100%;
  }
  
  .boxNomeDepoimento {
    width: 100%;
  }
  
  .nomeDepoimento {
    Font-family: "Roboto";
    font-weight: 600;
    Font-size: 2.4rem;
    Line-height: 3.4rem; 

    color: #333333;
  }
  
  .descriDepoimento {
    Font-family: "Roboto";
    font-weight: 400;
    font-style: italic;
    Font-size: 2rem;
    Line-height: 3.4rem; 

    color: #333333;
  }
  
 
  
  .pictureDepoimento{
    position: absolute;
   
    bottom: -6rem;

    width: 12rem;
    height: 12rem;

    border-radius: 50%;
  }

  .pictureDepoimento img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 767px) {

    .containerDepoimentos {
        padding: 3.2rem 1.6rem 7rem;
        gap: 3.2rem;
    }

    .ContainerTitleDepoimento {
         Font-size: 2.4rem;
         line-height: 3.4rem;
    }

    .containerInfoDepoimentos {
        flex-direction: column;
        gap: 6rem;
    }

    .boxDepoimentos {
        width: 100%;
        height: 24.2rem;      
        padding: 2.3rem 1.7rem 6rem;
    }

    .textDepoimentos {
        Font-size: 1.6rem;
        Line-height: 2.6rem; 
      }
      
      .boxNomeDepoimento {
        width: 100%;
      }
      
      .nomeDepoimento {
        Font-size: 1.6rem;
        Line-height: 2.6rem; 
      }
      
      .descriDepoimento {
        Font-size: 1.4rem;
        Line-height: 2.6rem;
      }
      
     
      
      .pictureDepoimento{
        bottom: -4rem;
        width: 8rem;
        height: 8rem;
      }  
  }