.screamVideosFuja {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;

   
  
    padding: 4rem;
    background-color: #333333;

  }

  .titleVideos {
    font-weight: 800;
    font-size: 3.6rem;
    line-height: 4.8rem;
  
    text-align: center;
  
    color: #ffffff;
  }

  .subTitleVideos {
    font-weight: 400;
    font-size: 2.6rem;
    line-height: 3.6rem;
  
    text-align: center;
  
    color: #ffffff;
  }

  .boxVideos {
    display: flex;
    gap: 2.4rem;
  }

  .videoItens{
    display: flex;
    flex-direction: column;
    width: 32rem;

    gap: 0.8rem;
  }

  .video {
    width: 32rem;
    height: 20.8rem;
  }

  .videoItens p{
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 600;

    color: #ffffff;
  }

  #frameVideo {
    width: 100%;
    height: 100%;
  }

  #btnPortfolioHome {
    all: unset;
  
    display: flex;
  
    justify-content: center;
    align-items: center;
  
    background-color: transparent;
  
    width: 24.8rem;
    height: 4rem;
    border: 0.1rem solid #ffffff;
    border-radius: 3.2rem;
  
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
  
    color: #ffffff;
  
    cursor: pointer;
  }
  
  #btnPortfolioHome:hover {
    background-color: #becc40;
    color: #333333;
  }

  @media screen and (max-width: 767px) {

    .screamVideosFuja{
        padding: 2.4rem 1.6rem;
    }

    .titleVideos{
        font-size: 2rem;
        line-height: 3rem;
    }

    .titleVideos br{
        display: none;
    }

    .subTitleVideos{
       font-size: 1.6rem;
       line-height: 2.6rem; 
    }

    .subTitleVideos br{
        display: none;
    }

  .boxVideos {
  flex-direction: column;
    
  }

}