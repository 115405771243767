* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  font-family: "Roboto", sans-serif;
}

html {
  width: 100%;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

button {
  cursor: pointer;
}