.boxVideosPortfolio {
    display: flex;
    flex-direction: column;
    gap: 8.3rem;
    
  
    background-color: #333333;
    padding:5.6rem 12rem 8.3rem;
  }

  .boxTitleVideos {
  
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 3.4rem;
  
    color: #FFFFFF;
  }

  .ContainerMoviePortfolio {
    display: flex;
    width: 100%;
    gap: 2.4rem;
  
  }

  .boxInfosVideoPortfolio {
    display: flex;
    width: 57.6rem;
    height: 100%;
    background-color: #333333;
    flex-direction: column;
  
  }

  .txtVideoPortfolio {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 4.4rem;
  
    color: #FFFFFF;
  
  }

.boxVideoPortfolio{
    width: 55.2rem ;
    height: 30.5rem;
  
    background-repeat: no-repeat;
    background-position: cover;
}

.imgVideoPortfolio{
    width: 100% ;
    height: auto
}

  .boxBtnVideo {
    display: flex;
  
    align-items: center;
    height: 12.1rem;

  
  }

  
#btn1 {
    all: unset;
  
    display: flex;
  
    justify-content: center;
    align-items: center;
  
    color: #FFFFFF;
  
    cursor: pointer;
  
    border: 0.1rem solid #FFFFFF;
  
    height: 4rem;
    width: 16.6rem;
    border-radius: 3.2rem;
  
    font-family: Roboto;
  font-weight: 500;
  font-size: 1.6rem;
  
  }
  
  #btn1:hover {
    transition: 0.3s;
    background-color: #62CBE9;
    color: #333333;
  }
  
  #btn2 {
  
    all: unset;
  
    display: flex;
  
    justify-content: center;
    align-items: center;
  
    color: #FFFFFF;
  
    border: 0.1rem solid #FFFFFF;
  
    cursor: pointer;
  
    height: 4rem;
    width: 13rem;
  
    border-radius: 3.2rem;
  
    font-family: Roboto;
    font-weight: 500;
    font-size: 1.6rem;
  }
  
  #btn2:hover {
    transition: 0.3s;
    background-color: #62CBE9;
    color: #333333;
  }

  
@media screen and (max-width: 767px) {
    .boxVideosPortfolio {
        padding:3.3rem 1.6rem;
    }

    .boxTitleVideos {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2rem;
      }

    .ContainerMoviePortfolio {
        display: flex;
        width: 100%;
        flex-direction: column;
        
        background-color: #333333;
  }

  .boxInfosVideoPortfolio {
    display: flex;
    width: auto;
    height: 100%;
    background-color: #333333;
    flex-direction: column;
  
  }

  .boxVideoPortfolio {
  
    width: 100% ;
    height: 19rem;
  
    background-repeat: no-repeat;
    background-position: cover;
  }

  .txtVideoPortfolio {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.6rem;
  
    color: #FFFFFF;
  
  }

  

}