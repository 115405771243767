.footer {
  height: 23rem;
  
  display: flex;
  padding: 5rem 10.7rem ;

  color: #fff;
  background-color: #333;
}

.footer a {
  all: unset;
}

.left-container {
  width: 45%;

  display: flex;
  align-items: center;
  gap: 4rem;

  border-right: solid 0.2rem #666;
}

.logo {

  border-radius: 50%;
  cursor: pointer;
  height: 11.2rem;
}

.left-container p {
  width: 28.2rem;
  height: 12.6rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;

  color: #FFFFFF;
}

.right-container {
  display: flex;
  align-items: center;
  gap: 8rem;
}

.footer nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  margin-left: 7.4rem;
}

.footer nav a {
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  text-decoration: underline;
}

nav a:hover {
  color: #becc40;
}

.social-media {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2.4rem;
}

.social-media p {
  font-size: 1.7rem;
  font-weight:700;
  
}

.social-media-icons {
  display: flex;
  gap: 1.2rem;
 
}

.social-media-icons a {
  cursor: pointer;
}

.contact{
  display: flex;
  gap: 1rem;
}

.contact a {
  font-size: 1.6rem;
  font-weight: 400;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .footer{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }

  .left-container{
    display: flex;
    gap: 1.6rem;

    width: 100%;

flex-direction: column;
border-right: none;
border-bottom: solid 0.2rem #666 ;
text-align: center;

padding: 2.4rem 7.4rem;
  }

  .logo {

    height: 7.6rem;
  }

  .left-container p {
    width: 100%;
    height: auto;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
  
    color: #FFFFFF;
  }

  .right-container{
    width: 100%;
    flex-direction: column;
    padding: 0;
    gap: 0;
  }

  .right-container nav{
    display: flex;
    align-items: center;

    padding: 2.4rem;

    margin: 0;
    width: 100%;
    border-bottom: solid 0.2rem #666 ;
  }

  .social-media{
    padding: 2.4rem;
    align-items: center;
  }

  .social-media p{
    text-align: center;
  }

  .contact{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact p{
    display: none;
  }
}